import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    artist: [
      {
        name: "JOEL BEST",
        pic: require("@/assets/team/LUCKY_LIONS_05.jpg"),
        pp: require("@/assets/team/LUCKY_LIONS_05.jpg"),
        descr: "Joel Best is a Master 3D Modeller /Sculptor and a highly skilled generalist 3D artist, with over 8 years experience working in VFX and crafting creatures for movies and TV. After working on prestigious projects for studios such as Netflix, HBO and Amazon Studios, he is now the Lead 3D Designer and Artistic Director of the Lucky Lion Club. His attention to detail and his skills in Scuplting, Modelling, Texturing and Lighting, have allowed us to bring our Lions roaring to life",
        side: true,
        az: true,
        hbo: true,
        ds: false,
        nt: true,
        insta: "https://www.instagram.com/joelbestart/",
        art: "",
      },
      {
        name: "STEPHEN GOODSON",
        pic: require("@/assets/team/LUCKY_LIONS_02.jpg"),
        pp: require("@/assets/team/LUCKY_LIONS_02.jpg"),
        descr: "Stephen Goodson is one of the most dedicated and talented CG artists. The experience he acquired working on projects for companies such as Disney, HBO and Netflix have helped Stephen grow into the talented artist he is today. As a master of creature groom, Stephen has simulated every hair on the head of our Lucky Lions. He brings a touch of realism that allows our lions to look like they are coming straight out of the jungle.",
        side: false,
        az: false,
        hbo: true,
        ds: true,
        nt: true,
        insta: "https://www.instagram.com/stephen.goodson.art/",
        art: "",
      },
      {
        name: "AARON HUNWICK",
        pic: require("@/assets/team/LUCKY_LIONS_03.jpg"),
        pp: require("@/assets/team/LUCKY_LIONS_03.jpg"),
        descr: "Aaron Hunwick is a passionate and enthusiastic 3D generalist with over 13 years experience in the film, TV and video game industries in New Zealand and the UK. He has worked on many popular projects, most notably The Hobbit Trilogy and The Witcher. Thanks to these skills Aaron is able to masterfully craft some of the costumes and accessories for our Lucky Lions.",
        side: false,
        az: false,
        hbo: false,
        ds: true,
        nt: false,
        insta: "https://instagram.com/aaronhunwickart?utm_medium=copy_link",
        art: "",
      }
    ],
    team: [
      {
        name: "Marie Fischer",
        title: "Event Project Manager",
        pic: require("@/assets/team/LUCKY_LIONS_01.jpg"),
        insta: "",
        linkedin: "https://www.linkedin.com/in/marie-fischer-b2ba39120/",
        artstation: "",
        discord: "",
        twitter: "",
      }, {
        name: "Pierre Etienne",
        title: "Blockchain & Solidity Consultant & Advisor",
        pic: require("@/assets/team/STONNER-LION.jpg"),
        insta: "",
        linkedin: "https://www.linkedin.com/in/blockchain-pierre-etienne-de/",
        artstation: "",
        discord: "",
        twitter: "",
      },
      {
        name: "Fréderick Dupray",
        title: "Head of Tech & Development",
        pic: require("@/assets/team/LUCKY_LIONS_04.jpg"),
        insta: "",
        linkedin: "https://www.linkedin.com/in/frederick-dupray-9499bab5/",
        artstation: "",
        discord: "",
        twitter: "",
      },
      {
        name: "Tom Capiez",
        title: "Marketing & Operations Strategist",
        pic: require("@/assets/team/PEAKY-LION.jpg"),
        insta: "",
        linkedin: "https://www.linkedin.com/in/tom-capiez-82a702175/",
        artstation: "",
        discord: "",
        twitter: "",
      },
      {
        name: "Dr. Rashad El Hajj",
        title: "Investor & Business Angel",
        pic: require("@/assets/team/COWBOY-LION.jpg"),
        insta: "",
        linkedin: "https://www.linkedin.com/in/rashad-el-hajj-66a2a91b1/",
        artstation: "",
        discord: "",
        twitter: "",
      },
      {
        name: "Jadey",
        title: "Community Manager",
        pic: require("@/assets/team/WHITE_LION_PROMO.jpg"),
        insta: "",
        linkedin: "",
        artstation: "",
        discord: "",
        twitter: "",
      },
      {
        name: "Menish",
        title: "Head Moderator",
        pic: require("@/assets/team/image-88.jpg"),
        insta: "",
        linkedin: "",
        artstation: "",
        discord: "",
        twitter: "",
      },
    ],
    faq: [
      {
        q: "Do you have a Whitelist ? How do I join it ?",
        r: "We create the most exclusive and private club for our members, who will have access to the craziest events around the world. We take great care in selecting each person who accesses the Whitelist and meets the requirements available in the discord.",
      },
      {
        q: "What Blockchain is the project hosted on ?",
        r: "The Lucky Lion Club is on the Ethereum Blockchain.",
      },
      {
        q: "When is the Public Sale ?",
        r: "Our Mint Sale will take place on February 4th. Raffle Sale will start at 5PM UTC. The whitelisted people will be able to mint 2 hours prior, at 3PM UTC. Only the winners of the Raffle will be able to mint during the public sale.",
      },
      {
        q: "What is the mint price ?",
        r: "The mint price is 0.2 ETH + gas.",
      },
      {
        q: "How can I register for the Raffle ?",
        r: "You'll be able to register on our website on Thursday, February 3rd at 3PM UTC. You will need enough funds on your wallet to mint at least one LLC NFT.",
      },
      {
        q: "How do I know I won the Raffle ?",
        r: "You'll have to go on our website on Friday, February 4th and connect your metamask account to see if you've won. If you won, you'll be able to mint your LLC NFTs right away.",
      },
      {
        q: "Do I need to register to the Raffle if I'm whitelisted ?",
        r: "If you are whitelisted, you'll be able to mint 2 hours prior the public sale on Friday, 4th February at 3PM UTC. You'll be guaranteed to mint up to 2 NFTs. You can however register with a different Ethereum address.",
      }
    ]
  }
})
