<template>
  <div class="roadmap">
    <div class="rLeft hidemob">
      <div class="box hide"></div>
      <div class="box" id="r2">
        <p class="title2 rTitle">LLC MERCHANDISING</p>
        <p class="text rText rl">
          We have been working behind the scenes for weeks now. As an exclusive holder of an LLC, you will get a Unique Membership to get access to our LLC Private Shop. We have been working on some amazing designs and accessories to swag your style with some LLC Merch. The merchandising is reserved
          for our holders only and you will be limited to 1 article per category. Those will be limited editions and none of the articles will be restocked. The online store will launch in early April. As you are our holders and fuel of the project, we want to make something exclusive and innovative
          from a simple Merchandising that would not bring some value to the experience of being a Lucky Lion. That’s why accessing the LLC Private Shop will be a chance and a lot of rewards will be given away and allocated to the holders depending on the role you got. All the team is working hard
          on all aspects of this element, and can’t wait to see you all wear some LLC merch proudly, in our virtual AND physical events.
        </p>
      </div>
      <div class="box hide"></div>
      <div class="box" id="r4">
        <p class="title2 rTitle">LION TAKING OVER THE WORLD</p>
        <p class="text rText rl">
          As stated in our roadmap, every holder will be granted to our real-life event. Our first party will happen in NEW YORK during The famous NFT NYC Event. A fixed date is still to be determined but it will happen between June 19th and 25th 2022. You will be able to meet your fellow lion’s
          friend in the Big Apple city while partying and networking with our community. We are planning to organize a big Giveaway during April where the winners will benefit from flight tickets, hotel booking, and free access for NFT.NYC. This comes in addition to the rewards obtained by the
          special roles we announced earlier. Remember, your network is your net worth…
        </p>
      </div>
    </div>
    <div class="middle hide" id="rmiddle">
      <div class="circle cc1"></div>
      <div class="circle cc2"></div>
      <div class="circle cc3"></div>
      <div class="circle cc4"></div>
    </div>
    <div class="rRight hidemob">
      <div class="box" id="r1">
        <p class="title2 rTitle">INTO THE METAVERSE</p>
        <p class="text rText rr">
          Owning A Lucky Lion will not only bring you access to real world events but also give you access to the future of the Metaverse; We truly believe that the Metaverse economy will shift tremendously in the next few years. Our Lucky Lion Headquarters are already live on Decentraland, and we
          are planning to organize unbelievable events and parties. The first Virtual Party is set on Saturday, 26th of March with a big DJ guest and an exclusive airdrop for the people who will come to the party owning a Lucky Lion.
        </p>
      </div>
      <div class="box hide"></div>
      <div class="box" id="r3">
        <p class="title2 rTitle">CHARITY</p>
        <p class="text rText rr">
          Humans have a big responsibility for the deteriorating conditions of animals in the wild. After honoring Lions with the best artwork possible, it's time for us to help nature in the real world. We've shortlisted 4 organizations whose goal is to protect our beloved wildlife. The decision is
          common, so we will organize a vote between these associations, to choose which one will receive a $15,000 donation. The 4 organizations are : Wild Tomorrow / Wild Animal Sanctuary / Space For Giants / African Conservation. "Never doubt that a small group of thoughtful committed citizens
          can change the world: indeed, it's the only thing that ever has."
        </p>
      </div>
      <div class="box hide"></div>
    </div>

    <div class="showmob">
      <div class="box" id="r1">
        <p class="title2 rTitle">INTO THE METAVERSE</p>
        <p class="text rText rr">
          Owning A Lucky Lion will not only bring you access to real world events but also give you access to the future of the Metaverse; We truly believe that the Metaverse economy will shift tremendously in the next few years. Our Lucky Lion Headquarters are already live on Decentraland, and we
          are planning to organize unbelievable events and parties. The first Virtual Party is set on Saturday, 26th of March with a big DJ guest and an exclusive airdrop for the people who will come to the party owning a Lucky Lion.
        </p>
      </div>
      <div class="box" id="r2">
        <p class="title2 rTitle">LLC MERCHANDISING</p>
        <p class="text rText rl">
          We have been working behind the scenes for weeks now. As an exclusive holder of an LLC, you will get a Unique Membership to get access to our LLC Private Shop. We have been working on some amazing designs and accessories to swag your style with some LLC Merch. The merchandising is reserved
          for our holders only and you will be limited to 1 article per category. Those will be limited editions and none of the articles will be restocked. The online store will launch in early April. As you are our holders and fuel of the project, we want to make something exclusive and innovative
          from a simple Merchandising that would not bring some value to the experience of being a Lucky Lion. That’s why accessing the LLC Private Shop will be a chance and a lot of rewards will be given away and allocated to the holders depending on the role you got. All the team is working hard
          on all aspects of this element, and can’t wait to see you all wear some LLC merch proudly, in our virtual AND physical events.
        </p>
      </div>
      <div class="box" id="r3">
        <p class="title2 rTitle">CHARITY</p>
        <p class="text rText rr">
          Humans have a big responsibility for the deteriorating conditions of animals in the wild. After honoring Lions with the best artwork possible, it's time for us to help nature in the real world. We've shortlisted 4 organizations whose goal is to protect our beloved wildlife. The decision is
          common, so we will organize a vote between these associations, to choose which one will receive a $15,000 donation. The 4 organizations are : Wild Tomorrow / Wild Animal Sanctuary / Space For Giants / African Conservation. "Never doubt that a small group of thoughtful committed citizens
          can change the world: indeed, it's the only thing that ever has."
        </p>
      </div>
      <div class="box" id="r4">
        <p class="title2 rTitle">LION TAKING OVER THE WORLD</p>
        <p class="text rText rl">
          As stated in our roadmap, every holder will be granted to our real-life event. Our first party will happen in NEW YORK during The famous NFT NYC Event. A fixed date is still to be determined but it will happen between June 19th and 25th 2022. You will be able to meet your fellow lion’s
          friend in the Big Apple city while partying and networking with our community. We are planning to organize a big Giveaway during April where the winners will benefit from flight tickets, hotel booking, and free access for NFT.NYC. This comes in addition to the rewards obtained by the
          special roles we announced earlier. Remember, your network is your net worth…
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Roadmap",
  data() {
    return {};
  },
  mounted() {},
  methods: {
    goToExternal(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.roadmap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 900px;
  //border: 1px solid red;
}

.middle {
  margin-top: 0px;
  height: 1480px;
  width: 0;
  margin: 0 25px;
  border-left: 6px solid $white;
  filter: drop-shadow(0px 0px 1px #ffffff);
}

.circle {
  width: 35px;
  height: 35px;
  // border: 3px solid $gris-light;
  border-radius: 50%;
  background-color: $white;
  margin-left: -21px;
  margin-top: 0px;
  margin-bottom: 346px;
  filter: drop-shadow(0px 0px 1px #ffffff);
}

.box {
  width: 100%;
  height: 380px;
  //border: 1px solid red;
}

.rLeft {
  width: 50%;
  text-align: right;
  //border: 1px solid red;
}

.rRight {
  width: 50%;
  text-align: left;
  //border: 1px solid red;
}

.rTitle {
  margin-top: -2px;
  margin-bottom: 25px;
  font-size: 25px;
}
.rText {
  font-size: 16px;
  //max-width: 400px;
}

.rl {
  text-align: right;
}
.rr {
  text-align: left;
}

@media screen and (min-width: $layout-breakpoint-medium) {
  .showmob {
    display: none;
  }
}

@media screen and (max-width: $layout-breakpoint-large) {
  .middle {
    height: 1120px;
  }
}

@media screen and (max-width: $layout-breakpoint-medium) {
  .rl {
    text-align: center;
  }
  .rr {
    text-align: center;
  }
  .roadmap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  .hide {
    display: none;
  }
  .rLeft {
    width: 100%;
    text-align: center;
  }
  .rRight {
    width: 100%;
    text-align: center;
  }
  .rTitle {
    font-size: 20px;
  }
  .box {
    margin-bottom: 50px;
    height: auto;
  }

  .hidemob {
    display: none;
  }
}
</style>
