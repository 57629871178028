<template>
  <div class="footer">
    <img class="col1" :src="require(`@/assets/imgs/lakers_silver_alpha.png`)" />

    <div class="col2">
      <img class="logo" :src="require(`@/assets/imgs/LOGO.png`)" @click="goTo('Home')" />
      <p class="title1 footTitle">Wanna join the club ?</p>
      <div class="inline">
        <button class="b" @click="goToExternal('https://discord.gg/luckylionclub')">Discord</button>
        <button class="b" @click="goToExternal('https://twitter.com/Lucky_Lion_Club')">Twitter</button>
      </div>
      <div class="opensea-container">
        <button class="b" @click="goToExternal('https://opensea.io/collection/llc-lucky-lion-club-official')">
          <img class="social-logo opensea" style="width: 35px; height: 35px" :src="require(`@/assets/logos/Logomark-Transparent White.png`)" />
          Opensea
        </button>
      </div>
    </div>
    <div class="col3">
      <a href="" class="text" style="cursor: default"><b>Menu</b></a>
      <a href="#artists" class="text navLink">Artists</a>
      <a @click="goTo('Csoon')" href="" class="text navLink">Shop</a>
      <a href="#roadmap" class="text navLink">Roadmap</a>
      <a href="#about" class="text navLink">About</a>
      <a href="#team" class="text navLink">The Team</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    goToExternal(url) {
      window.open(url);
    },
    goTo(name) {
      console.log(name);
      if (this.$route.name !== name) {
        this.$router.push({ name });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.opensea-container {
  text-align: center;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.opensea-container button {
  padding: 10px 30px;
  img {
    height: 60px;
    margin-right: 20px;
  }
}

.footer {
  margin-top: 200px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  text-align: center;
  // height: 500px;
  background: $black;
}

.col1 {
  margin-top: -150px;
  width: 30%;
  //max-width: 300px;
  object-fit: cover;
}

.col2 {
  width: 50%;
  //border: 1px solid red;
  margin: 50px;
  // margin-top: 0px;
  // padding-top: 50px;
}

.logo {
  object-fit: contain;
  max-height: 60px;
  width: 90%;
  margin-bottom: 25px;
  //object-fit: cover;
}

.b {
  margin-left: 10px;
  margin-right: 10px;
}

.footTitle {
  font-size: 25px;
}

.col3 {
  padding-top: 50px;
  padding-bottom: 50px;
  width: 20%;
  //border: 1px solid red;
  margin: 50px;
  // margin-top: 0px;
  // line-height: 15px;
  // font-weight: 100;
  // padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.text {
  text-align: left;
  line-height: 40px;
}

.navLink {
  &:hover {
    font-weight: 400;
  }
}

@media screen and (max-width: $layout-breakpoint-large) {
  .col1 {
    display: none;
  }
}

@media screen and (max-width: $layout-breakpoint-medium) {
  .col3 {
    display: none;
  }
  .col2 {
    width: 100%;
    margin: 50px 0px;
  }
}
</style>
