<template>
  <div class="page">
    <!-- TOPVIEW -->
    <div class="viewContainer topView">
      <div class="center topCenter">
        <div class="topLeft">
          <p class="title2">GET A LUCKY LION NOW</p>
          <button style="margin: auto" @click="goToExternal('https://opensea.io/collection/llc-lucky-lion-club-official')">
            <img :src="require(`@/assets/logos/Logomark-Transparent White.png`)" style="width: 35px; margin-right: 15px" />
            Buy on OpenSea
          </button>
        </div>
        <!-- <div class="topRight"> -->
        <img class="topViewImg2" :src="require(`@/assets/imgs/header.png`)" />
        <!-- </div> -->
      </div>
    </div>

    <!-- ENTER THE CLUB -->
    <div id="about" class="viewContainer club">
      <div class="center clubCenter">
        <p class="title2">THE MOST EXCLUSIVE CLUB</p>
        <img class="clubimg" :src="require(`@/assets/imgs/3 lions.png`)" />
        <p class="text clubText">
          The Lucky Lion Club is a <b>collection of 4000 unique 8K NFTs</b> crafted by world-renowned artist Joel Best. By owning a Lucky Lion, you will have access to a <b>private and exclusive community</b> of high-net-worth individuals spread across the world. We voluntarily decided to create
          ultra-realistic 3D artworks with an elite team of designers and marketers to only attract <b>valuable and high-quality holders</b> that will benefit the project long term’s success.
        </p>
      </div>
      <img class="paw3" :src="require(`@/assets/imgs/paw1.png`)" />
    </div>

    <!-- DECENTRAL -->
    <div class="viewContainer artist">
      <div class="center artistCenter">
        <img class="decentraLandTitle" :src="require(`@/assets/imgs/decentraland logo 1.png`)" />
        <button class="clubButton" @click="goToExternal('https://play.decentraland.org/?position=-67,-150')">Jump in our LLC HQ</button>
        <img class="decentraLandImg" :src="decentImgs[decentCurrent]" />
      </div>
      <img class="paw1" :src="require(`@/assets/imgs/paw1.png`)" />
      <img class="paw2" :src="require(`@/assets/imgs/paw2.png`)" />
    </div>

    <!-- OUR LUCKY -->
    <div class="viewContainer artist">
      <div class="center legendCenter">
        <p class="title2">OUR LUCKY LEGENDARY LIONS</p>
        <carousel-3d class="carou" style="padding-top: 50px; padding-bottom: 50px; height: 450px; width: 100%">
          <slide v-for="(img, i) in legendImgs" :key="i" :index="i" style="background: black; height: 335px; border: none; filter: drop-shadow(0px 0px 5px #ffffff); cursor: pointer"> <img class="legendImg" :src="img" /> </slide>
        </carousel-3d>
      </div>
      <img class="paw1" :src="require(`@/assets/imgs/paw1.png`)" />
      <img class="paw2" :src="require(`@/assets/imgs/paw2.png`)" />
    </div>

    <!-- BANNER -->
    <div id="banner" class="viewContainer banner">
      <v-lazy-image class="banner1 hidemob" :src="require(`@/assets/imgs/LIONS-BANNER-DISPLAYED.jpg`)" />
      <v-lazy-image class="banner1 showmob" :src="require(`@/assets/imgs/Frame-15.jpg`)" />
    </div>

    <!-- ROADMAP -->
    <div id="roadmap" class="viewContainer roadmap">
      <div class="center roadmapCenter">
        <p class="title2 roadTitle">Roadmap</p>
        <Roadmap class="roadmaap" />
      </div>
      <img class="liane1" :src="require(`@/assets/imgs/liane1.png`)" />
      <img class="liane2" :src="require(`@/assets/imgs/liane2.png`)" />
    </div>

    <!-- ARTIST -->
    <div id="artists" class="viewContainer artist">
      <div class="center artistCenter">
        <p class="title2 artistTitle">The artists</p>
        <p class="text artistText">Meet our 3D Artists Team who worked on the full creation process of the Lucky Lions, including modeling, groom and accessories. One thing is sure, you have already seen their work on your screens… They worked hard to give our Lucky Lions an unbelievable look!</p>
      </div>
      <div class="contentArtist ct2">
        <ArtistCard v-for="(item, i) in $store.state.artist" :key="'t' + i" :name="item.name" :pic="item.pic" :pp="item.pp" :descr="item.descr" :az="item.az" :hbo="item.hbo" :ds="item.ds" :nt="item.nt" :side="item.side" :insta="item.insta" :art="item.art" :id="'acard' + i" />
      </div>
      <img class="paw1" :src="require(`@/assets/imgs/paw1.png`)" />
      <img class="paw2" :src="require(`@/assets/imgs/paw2.png`)" />
    </div>

    <!-- TEAM -->
    <div id="team" class="viewContainer team">
      <div class="center">
        <p class="title2 teamTitle">The lion team</p>
      </div>
      <div class="contentTeam ct2">
        <TeamCard v-for="(item, i) in $store.state.team" :key="'t' + i" :name="item.name" :title="item.title" :pic="item.pic" :twitter="item.twitter" :insta="item.insta" :linkedin="item.linkedin" :discord="item.discord" :artstation="item.artstation" :id="'pcard' + i" />
      </div>
    </div>

    <!-- FAQ -->
    <!-- <div id="faq" class="viewContainer faq">
      <div class="center faqCenter">
        <div class="faqCard">
          <p class="title2 faqTitle">Frequently asked questions</p>
          <FaqLine v-for="(item, i) in $store.state.faq" :key="i" :q="item.q" :r="item.r" :id="'faq' + i"></FaqLine>
        </div>
      </div>
      <img class="paw1" :src="require(`@/assets/imgs/paw1.png`)" />
      <img class="paw2" :src="require(`@/assets/imgs/paw2.png`)" />
    </div> -->
  </div>
</template>

<script>
import ArtistCard from "@/components/ArtistCard";
import TeamCard from "@/components/TeamCard";
import Roadmap from "@/components/Roadmap";
// import FaqLine from "@/components/FaqLine";

import { Carousel3d, Slide } from "vue-carousel-3d";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import VLazyImage from "v-lazy-image/v2/v-lazy-image.umd";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "Home",
  components: { VLazyImage, ArtistCard, TeamCard, Roadmap, Carousel3d, Slide },
  data() {
    return {
      scrollMarker: false,
      legendImgs: [require(`@/assets/imgs/PIRATE_LEGENDARY 1.jpg`), require(`@/assets/imgs/SCUBA_FINAL.jpg`), require(`@/assets/imgs/mech_lion 1.jpg`), require(`@/assets/imgs/BOTH.jpg`), require(`@/assets/imgs/SPHYNX_FINAL.jpg`)],
      legendCurrent: 0,
      decentImgs: [require(`@/assets/imgs/DCL image.png`), require(`@/assets/imgs/Variant2.png`), require(`@/assets/imgs/Variant3.png`)],
      decentCurrent: 0,
    };
  },
  mounted() {
    if (!this.isMobile()) {
      //this.scrollAnimation();
    }
    setInterval(() => {
      this.decentCurrent++;
      if (this.decentCurrent > this.decentImgs.length - 1) this.decentCurrent = 0;
    }, 1000);
  },
  methods: {
    goToExternal(url) {
      window.open(url);
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
    scrollAnimation() {
      // Top view
      gsap
        .timeline({
          scrollTrigger: {
            trigger: ".topT1",
            start: "bottom bottom",
            end: "bottom bottom",
            markers: this.scrollMarker,
            scrub: false,
            pin: false,
          },
        })
        .from(".topViewImg2", { opacity: 0, delay: 1, duration: 1 })
        .from(".topT1", { x: -innerWidth, opacity: 0 })
        .from(".topT2", { x: innerWidth, opacity: 0 });

      // Club
      gsap
        .timeline({
          scrollTrigger: {
            trigger: ".clubTitle",
            start: "bottom bottom",
            end: "bottom bottom",
            markers: this.scrollMarker,
            scrub: false,
            pin: false,
          },
        })
        .from(".clubLeft", { x: -innerWidth, opacity: 0 })
        .from(".clubRight", { x: innerWidth, opacity: 0 });

      // Artists
      gsap
        .timeline({
          scrollTrigger: {
            trigger: ".artistTitle",
            start: "bottom bottom",
            end: "bottom bottom",
            markers: this.scrollMarker,
            scrub: false,
            pin: false,
          },
        })
        .from(".artistTitle", { opacity: 0 })
        .from(".artistText", { opacity: 0 })
        .from("#acard0", { x: -innerWidth, opacity: 0 })
        .from("#acard1", { x: innerWidth, opacity: 0 })
        .from("#acard2", { opacity: 0 })
        .from(".paw1", { opacity: 0 })
        .from(".paw2", { opacity: 0 });

      // Banner
      gsap
        .timeline({
          scrollTrigger: {
            trigger: ".banner",
            start: "bottom bottom",
            end: "bottom bottom",
            markers: this.scrollMarker,
            scrub: false,
            pin: false,
          },
        })
        .from(".banner1", { opacity: 0 });

      // Roadmap
      gsap
        .timeline({
          scrollTrigger: {
            trigger: ".roadTitle",
            start: "bottom bottom",
            end: "bottom bottom",
            markers: this.scrollMarker,
            scrub: false,
            pin: false,
          },
        })
        .from(".roadTitle", { opacity: 0 })
        .from("#rmiddle", { opacity: 0 })
        .from(".cc1", { opacity: 0 })
        .from("#r1", { x: innerWidth, opacity: 0 });

      gsap
        .timeline({
          scrollTrigger: {
            trigger: "#r1",
            start: "top top",
            end: "center center",
            markers: this.scrollMarker,
            scrub: false,
            pin: false,
          },
        })
        .from(".cc2", { opacity: 0 })
        .from("#r2", { x: -innerWidth, opacity: 0 });

      gsap
        .timeline({
          scrollTrigger: {
            trigger: "#r2",
            start: "top top",
            end: "center center",
            markers: this.scrollMarker,
            scrub: false,
            pin: false,
          },
        })
        .from(".cc3", { opacity: 0 })
        .from("#r3", { x: innerWidth, opacity: 0 });

      gsap
        .timeline({
          scrollTrigger: {
            trigger: "#r3",
            start: "top top",
            end: "center center",
            markers: this.scrollMarker,
            scrub: false,
            pin: false,
          },
        })
        .from(".cc4", { opacity: 0 })
        .from("#r4", { x: -innerWidth, opacity: 0 });

      // Team
      gsap
        .timeline({
          scrollTrigger: {
            trigger: ".teamTitle",
            start: "bottom bottom",
            end: "bottom bottom",
            markers: this.scrollMarker,
            scrub: false,
            pin: false,
          },
        })
        .from(".teamTitle", { opacity: 0 })
        .from("#pcard1", { x: -innerWidth, opacity: 0 })
        .from("#pcard2", { x: innerWidth, opacity: 0 })
        .from("#pcard0", { x: -innerWidth, opacity: 0 })
        .from("#pcard3", { x: innerWidth, opacity: 0 })
        .from("#pcard4", { x: -innerWidth, opacity: 0 });

      // Faq
      gsap
        .timeline({
          scrollTrigger: {
            trigger: ".faqTitle",
            start: "bottom bottom",
            end: "bottom bottom",
            markers: this.scrollMarker,
            scrub: false,
            pin: false,
          },
        })
        .from(".faqTitle", { opacity: 0 })
        .from("#faq0", { x: -innerWidth, opacity: 0 })
        .from("#faq1", { x: innerWidth, opacity: 0 })
        .from("#faq2", { x: -innerWidth, opacity: 0 })
        .from("#faq3", { x: innerWidth, opacity: 0 })
        .from("#faq4", { x: -innerWidth, opacity: 0 })
        .from("#faq5", { x: innerWidth, opacity: 0 })
        .from("#faq6", { x: -innerWidth, opacity: 0 });
    },
  },
};
</script>

<style lang="scss">
/*************************************** TOPVIEW *****************************************/
.topView {
  background-image: url("~@/assets/imgs/SAVANE BACKGROUND.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 80vh;
}

.topCenter {
  min-height: 80vh;
  max-width: 1200px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  //border: 1px solid blue;
  position: relative;
}

.topLeft {
  width: 50%;

  //border: 1px solid blue;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

// .topRight {
//   width: 50%;
//   height: 100%;
//   //border: 1px solid blue;
// }

.topViewImg2 {
  width: 50%;
  object-fit: contain;
  // border: 1px solid blue;
  position: absolute;
  bottom: 0;
  right: 0;
}

.topT1 {
  margin: auto;
  text-align: center;
  font-size: 50px;
  // font-weight: 600;
}

.topT2 {
  margin: auto;
  text-align: center;
  font-size: 25px;
  font-weight: 400;
}

@media screen and (max-width: $layout-breakpoint-medium) {
  .topView {
    min-height: 100vh;
  }
  .topCenter {
    min-height: 100vh;
  }
  .topT1 {
    font-size: 20px;
  }
  .topT2 {
    font-size: 20px;
  }

  .topLeft {
    width: 100%;
    //border: 1px solid blue;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-150%);
    z-index: 1000;
  }
  .topViewImg2 {
    width: 100%;
    object-fit: contain;
    // border: 1px solid blue;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

/*************************************** CLUB *****************************************/
.club {
  background-color: #191e2b;
  box-shadow: 0px 0px 10px #191e2b;
  z-index: 100;
  max-height: 200vw;
}

.clubCenter {
  display: flex;
  // flex-direction: row;
  justify-content: space-between;
  max-width: 900px;
}

.clubimg {
  max-height: 500px;
  object-fit: contain;
}

.clubTitle {
  // margin: auto;
  text-align: left;
  font-size: 22px;
}
.clubText {
  font-size: 16px;
  text-align: center;
  margin-top: 50px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.clubButton {
  max-width: 500px;
  margin: 50px auto;
}

.clubRight {
  width: 40%;
  //border: 1px solid red;
}

@media screen and (max-width: $layout-breakpoint-medium) {
  .clubCenter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 100px;
  }
  .clubLeft {
    width: 100%;
  }
  .clubTitle {
    text-align: center;
  }
  .clubText {
    text-align: center;
  }
  .clubRight {
    width: 100%;
  }
  .clubButton {
    width: 100%;
  }
}

/*************************************** Decentraland *****************************************/
.decentraLandImg {
  object-fit: contain;
}

.decentraLandTitle {
  width: 50%;
  min-width: 300px;
  max-height: 80px;
  margin: auto;
  object-fit: contain;
}

// .artist {
//   background-color: #1e263a;
// }

// .artistCenter {
//   margin: auto;
// }

// .decentraLandTitle {
//   width: 50%;
//   min-width: 300px;
//   max-height: 80px;
//   margin: auto;
//   object-fit: contain;
// }

// .paw1 {
//   position: absolute;
//   width: 150px;
//   left: 50px;
//   margin-top: 300px;
//   z-index: 0;
//   object-fit: contain;
// }

// .paw2 {
//   position: absolute;
//   width: 150px;
//   right: 50px;
//   margin-top: -300px;
//   z-index: 0;
//   object-fit: contain;
// }

// .paw3 {
//   position: absolute;
//   width: 150px;
//   left: 50px;
//   margin-top: -200px;
//   z-index: 0;
//   object-fit: contain;
// }

// .paw1,
// .paw2,
// .paw3 {
//   transition: all 100ms ease-in-out;
//   &:hover {
//     filter: drop-shadow(0px 0px 5px $white);
//   }
// }

// @media screen and (max-width: $layout-breakpoint-medium) {
//   .paw1,
//   .paw2,
//   .paw3 {
//     display: none;
//   }
// }

/*************************************** ARTIST *****************************************/
.artist {
  background-color: #1e263a;
}
.artistCenter {
  //border: 1px solid red;
  margin: auto;
}
.artistText {
  text-align: center;
  max-width: 900px;
  margin: auto;
  margin-bottom: 50px;
}
.contentArtist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: auto;
  width: 100%;
  max-width: 1400px;
  //border: 1px solid red;
}
.paw1 {
  position: absolute;
  width: 150px;
  left: 50px;
  margin-top: 300px;
  z-index: 0;
  object-fit: contain;
}
.paw2 {
  position: absolute;
  width: 150px;
  right: 50px;
  margin-top: -300px;
  z-index: 0;
  object-fit: contain;
}
.paw3 {
  position: absolute;
  width: 150px;
  left: 50px;
  margin-top: -200px;
  z-index: 0;
  object-fit: contain;
}
.paw1,
.paw2,
.paw3 {
  transition: all 100ms ease-in-out;
  &:hover {
    filter: drop-shadow(0px 0px 5px $white);
  }
}
@media screen and (max-width: $layout-breakpoint-medium) {
  .paw1,
  .paw2,
  .paw3 {
    display: none;
  }
}

/*************************************** LEGEND *****************************************/
.legendCenter {
  max-width: 100%;
}

.slider {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.carou {
  width: 100%;
  margin: auto;
  //border: 1px solid blue;
}

.slide {
  width: 500px;
  height: 500px;
}

.legendImg {
  // width: 500px;
  //height: 500px;
  object-fit: contain;
  //margin: auto;
}

/*************************************** BANNER *****************************************/

.banner {
  background-color: #3a4255;
}

.banner1 {
  width: 100%;
  object-fit: contain;
}

@media screen and (min-width: $layout-breakpoint-medium) {
  .showmob {
    display: none;
  }
}
@media screen and (max-width: $layout-breakpoint-medium) {
  .hidemob {
    display: none;
  }
}

/*************************************** ROADMAP *****************************************/
.roadmap {
  // background-color: #1e263a;
  justify-content: center;
  margin: auto;
  width: 100%;
  //border: 1px solid blue;
}

.roadmapCenter {
  //max-width: 1200px;
  //border: 1px solid red;
}

.roadTitle {
  font-size: 35px;
}

.roadmaap {
  margin-top: 100px;
  //border: 1px solid red;
}

.liane1 {
  position: absolute;
  //width: 20%;
  width: 400px;
  left: 0;
  margin-top: -500px;
  z-index: 0;
  object-fit: contain;
}

.liane2 {
  position: absolute;
  //width: 20%;
  width: 400px;
  right: 0;
  margin-top: 500px;
  z-index: 0;
  object-fit: contain;
}

@media screen and (max-width: $layout-breakpoint-medium) {
  .liane1,
  .liane2 {
    display: none;
  }
  .roadmaap {
    margin-top: 0px;
  }
}
/*************************************** Team *****************************************/
.team {
}

.teamTitle {
  color: $white;
  //max-width: 1200px;
  text-align: center;
  margin-bottom: 50px;
}

.contentTeam {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  width: 100%;
  max-width: 1600px;
  //border: 1px solid red;
}

/*************************************** FAQ *****************************************/
.faq {
}

.faqCenter {
  max-width: 980px;
}

.faqTitle {
  font-size: 22px;
}

.faqCard {
  background-color: #3a4255;
  border-radius: 20px;
  padding: 50px 100px;
  width: 100%;
}

@media screen and (max-width: $layout-breakpoint-medium) {
  .faqCard {
    background-color: #3a4255;
    border-radius: 0px;
    padding: 25px 25px;
    width: 100%;
  }
}
</style>
