<template>
  <div id="app">
    <Topbar v-if="this.$route.name !== 'Connectwallet' && this.$route.name !== 'Shop'" />
    <router-view />
    <Footer v-if="this.$route.name === 'Home'" />
  </div>
</template>

<script>
import Topbar from "@/components/Topbar";
import Footer from "@/components/Footer";

export default {
  name: "App",
  components: { Topbar, Footer },
};
</script>

<style lang="scss">
@import url("https://fonts.cdnfonts.com/css/matroska");
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,
body,
#app {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  background-color: $white;
  zoom: normal;
}
#app {
  font-family: MATROSKA;
  text-align: center;
  color: $white;
  margin: auto;
  overflow-x: hidden;
  background-color: $gris-dark;
}

* {
  box-sizing: border-box;
}

a {
  color: $white;
  text-decoration: none;
}

.viewContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  //min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  //border: 1px solid blue;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: 100%;
  //max-width: 900px;
  max-width: 1200px;
  padding-top: 150px;
  padding-bottom: 50px;
  z-index: 10;
  //border: 1px solid green;
}

.title1,
.title2 {
  font-family: MATROSKA;
  text-transform: uppercase;
  font-size: 40px;
  font-weight: 400;
  margin: auto;
  margin-bottom: 50px;
}

.title2 {
  font-size: 30px;
}

.text {
  text-align: left;
  color: #fff;
  font-family: Barlow;
  font-size: 20px;
  font-weight: 200;
}

.inline {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.howCanCol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  margin: auto;
}

.howCanColItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin: 10px;
  margin-bottom: 20px;
  align-items: left;
}

button {
  display: flex;
  font-family: MATROSKA;
  color: $white;
  background-color: #616161;
  padding: 15px 50px;
  border-radius: 100px;
  border: none;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  cursor: pointer;
  filter: drop-shadow(0px 0px 1px #616161);
  &:hover {
    filter: drop-shadow(0px 0px 2px #616161);
    opacity: 1;
    transform: translateY(-1px);
  }
}

@media screen and (max-width: $layout-breakpoint-xlarge) {
  .center {
    width: calc(100% - 50px);
    margin-left: 25px;
    margin-right: 25px;
  }
}

@media screen and (max-width: $layout-breakpoint-large) {
  .title1 {
    font-size: 40px;
    font-weight: 400;
  }
  .title2 {
    font-size: 35px;
  }
  .text {
    font-size: 23px;
  }
}

@media screen and (max-width: $layout-breakpoint-medium) {
  .title1 {
    font-size: 30px;
    margin-bottom: 25px;
  }
  .title2 {
    font-size: 30px;
    margin-bottom: 25px;
    line-height: 40px;
  }
  .text {
    font-size: 20px;
    font-weight: 300;
    line-height: 35px;
  }
  .center {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  button {
    padding: 20px 40px;
    border-radius: 15px;
  }
}

@media screen and (max-width: $layout-breakpoint-small) {
  .title1 {
    font-size: 25px;
  }
  .title2 {
    font-size: 25px;
  }
  .text {
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
  }
  .center {
    padding-top: 50px;
    padding-bottom: 0px;
  }
  button {
    padding: 20px 30px;
    border-radius: 15px;
  }
}
</style>
