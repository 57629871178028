<template>
  <div class="teamMember">
    <!-- <img class="minipic" :src="pp" v-if="side" />
    <img class="minipicR" :src="pp" v-if="!side" /> -->
    <div class="picContainer">
      <img class="pic" :src="pic" />
    </div>
    <p class="text name">
      {{ name }}
      <i class="fab fa-instagram" v-if="insta != ''" @click="goToExternal(insta)"></i>
      <img class="logoArt" :src="require(`@/assets/logos/artstation-icon.png`)" v-if="art != ''" @click="goToExternal(art)" />
    </p>
    <p class="text descr" v-html="descr"></p>
    <div class="inline">
      <!-- <p class="text former">Former clients:</p> -->
      <img class="logoA" :src="require(`@/assets/logos/AZ.png`)" v-if="az" />
      <img class="logoA" :src="require(`@/assets/logos/DS.png`)" v-if="ds" />
      <img class="logoA" :src="require(`@/assets/logos/HBO.png`)" v-if="hbo" />
      <img class="logoA" :src="require(`@/assets/logos/NT.png`)" v-if="nt" />
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamCard",
  props: {
    name: String,
    pic: String,
    pp: String,
    side: Boolean,
    descr: String,
    az: Boolean,
    hbo: Boolean,
    ds: Boolean,
    nt: Boolean,
    insta: String,
    art: String,
  },
  data() {
    return {
      style: "",
    };
  },
  mounted() {},
  methods: {
    goToExternal(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.fa,
.fab {
  color: $white;
  font-size: 25px;
  //opacity: 0.85;
  cursor: pointer;
  &:hover {
    filter: drop-shadow(0px 0px 1px $white);
    opacity: 1;
    transform: translateY(-1px);
  }
}

.logoArt {
  width: 25px;
  margin-bottom: -5px;
  margin-top: 5px;
  //opacity: 0.85;
  cursor: pointer;
  &:hover {
    filter: drop-shadow(0px 0px 1px $white);
    opacity: 1;
    transform: translateY(-1px);
  }
}

.teamMember {
  transition: all 500ms ease-in-out;
  margin-bottom: 50px;
  // margin-left: 30px;
  // margin-right: 30px;
  width: 350px;
  z-index: 100;
  > * {
    transition: all 100ms ease-in-out;
  }
  //border: 1px solid blue;
}

.pic {
  width: 250px;
  height: 250px;
  object-fit: cover;
  z-index: 100;
}

.minipicR,
.minipic {
  position: relative;
  margin-left: -300px;
  margin-bottom: -100px;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  z-index: 120;
}
.minipicR {
  margin-left: 300px;
}

.name {
  text-transform: uppercase;
  font-size: 18px;
  line-height: 28px;
  margin: auto;
  margin-bottom: -5px;
  margin-top: 10px;
  width: 100%;
  text-align: center;
  color: $white;
  font-family: MATROSKA;
}

.descr {
  width: 100%;
  height: 200px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

.logoContainer2 {
  width: 50px;
  min-width: 50px;
  height: 50px;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //opacity: 0.85;
}

.former {
  font-size: 15px;
  margin-top: 20px;
}

.logoA {
  height: 18px;
  width: 110px;
  object-fit: contain;
  // border: 1px solid red;
  margin-top: 22px;
  transition: all 100ms ease-in-out;
  cursor: pointer;
  // filter: drop-shadow(0px 0px 1px #ffffff);
  &:hover {
    //filter: drop-shadow(0px 0px 1px #ffffff);
    opacity: 1;
    transform: translateY(-1px);
    height: 20px;
    width: 110px;
    margin-top: 22px;
    margin-bottom: -5px;
    // margin-left: -5px;
    // margin-right: -5px;
  }
}

@media screen and (max-width: $layout-breakpoint-medium) {
  .teamMember {
    width: 300px;
    margin-bottom: 50px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .descr {
    font-size: 15px;
  }
  .minipicR,
  .minipic {
    margin-left: -200px;
    margin-bottom: -75px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .minipicR {
    margin-left: 200px;
  }
  .pic {
    width: 250px;
    height: 250px;
  }
  .name {
    font-size: 18px;
  }
  .former {
    display: none;
  }
  .fa {
    font-size: 22px;
  }
  .fab {
    font-size: 25px;
  }
  .logoContainer2 {
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //opacity: 0.85;
  }
}
</style>
