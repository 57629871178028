import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
//import Csoon from "./views/Csoon.vue";
/*import Mint from "./views/Mint.vue";
import Mint1 from "./views/Mint1.vue";
import MintRaffle from "./views/MintRaffle.vue";*/
import Connectwallet from "./views/Connectwallet.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/shop",
      name: "Shop",
      component: Connectwallet,
    },
    {
      path: "/connectwallet",
      name: "Connectwallet",
      component: Connectwallet,
    },
    /*{
      path: "/whitelist",

      name: "Mint",
      component: Mint,
    },
    {
      path: "/whitelist1",

      name: "Mint1",
      component: Mint1,
    },
    {
      path: "/mint",
      name: "MintRaffle",
      component: MintRaffle,
    },*/
  ],
});
