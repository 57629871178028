<template>
  <div class="topbar-container">
    <div class="topbar">
      <img class="logo" :src="require(`@/assets/imgs/LOGO.png`)" @click="goTo('Home')" />
      <img class="logo_small" :src="require(`@/assets/imgs/LOGO_small.png`)" @click="goTo('Home')" />
      <a href="#" @click="goToExternal('https://opensea.io/collection/llc-lucky-lion-club-official?search[sortAscending]=true&search[sortBy]=PRICE&search[toggles][0]=BUY_NOW')" class="nav-item mainNav">Buy Now</a>
      <a href="#roadmap" class="nav-item mainNav">Roadmap</a>
      <a href="#artists" class="nav-item mainNav">Artists</a>
      <!-- <a href="#faq" class="nav-item mainNav">FAQ</a> -->
      <a @click="goTo('Shop')" class="nav-item mainNav">Shop</a>

      <div class="right">
        <i class="fab fa-discord" @click="goToExternal('https://discord.gg/luckylionclub')"></i>
        <i class="fab fa-twitter" @click="goToExternal('https://twitter.com/Lucky_Lion_Club')"></i>
        <img class="logoOpenSea" :src="require(`@/assets/logos/Logomark-Transparent White.png`)" />
      </div>

      <div class="burger">
        <i class="fa fa-bars" @click="hide = !hide"></i>
      </div>
    </div>

    <div class="mobile" v-if="hide">
      <a href="#about" class="mob-nav-item" @click="hide = false">About</a>
      <a href="#roadmap" class="mob-nav-item" @click="hide = false">Roadmap</a>
      <a href="#artists" class="mob-nav-item" @click="hide = false">Artists</a>
      <!-- <a href="#faq" class="mob-nav-item" @click="hide = false">FAQ</a> -->
      <a
        @click="
          hide = false;
          goTo('Csoon');
        "
        class="mob-nav-item"
        >Shop</a
      >
      <div class="mob-nav-item mobLogo">
        <i class="fab fa-discord" @click="goToExternal('https://discord.gg/luckylionclub')"></i>
        <i class="fab fa-twitter" @click="goToExternal('https://twitter.com/Lucky_Lion_Club')"></i>
        <img class="logoOpenSea" :src="require(`@/assets/logos/Logomark-Transparent White.png`)" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Topbar",
  data() {
    return {
      hide: false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.onresize);
  },
  methods: {
    onresize() {
      this.hide = false;
    },
    goToExternal(url) {
      window.open(url);
    },
    goTo(name) {
      console.log(name);
      if (this.$route.name !== name) {
        this.$router.push({ name });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.topbar-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4000;
  font-family: Barlow;
  font-style: normal;
  font-weight: 200;
  color: $white;
}

.topbar {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  height: 80px;
  padding: 0px 50px;
  margin-top: 10px;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  background: transparent;
  align-items: center;
  z-index: 5000;
}

.nav-item {
  height: 40px;
  letter-spacing: 0;
  line-height: 40px;
  //font-size: 35px;
  font-size: 25px;
  margin-right: 25px;
  cursor: pointer;
  //text-transform: uppercase;
  //opacity: 0.9;
  transition: all 100ms ease-in-out;
  &:hover {
    filter: drop-shadow(0px 0px 1px #ffffff);
    opacity: 1;
    transform: translateY(-1px);
  }
}

.logo {
  height: 50%;
  z-index: 100;
  margin-right: 30px;
  cursor: pointer;
  object-fit: cover;
  &:hover {
    filter: drop-shadow(0px 0px 1px #ffffff);
    opacity: 1;
    transform: translateY(-1px);
  }
}

.logo_small {
  display: none;
  height: 70%;
  z-index: 100;
  margin-left: -15px;
  margin-top: 0px;
  cursor: pointer;
  object-fit: cover;
  &:hover {
    filter: drop-shadow(0px 0px 1px #ffffff);
    opacity: 1;
    transform: translateY(-1px);
  }
}

.right {
  width: 150px;
  //width: 100px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  .fab {
    font-size: 22px;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    padding-top: 9px;

    //font-size: 32px;
    cursor: pointer;
    //opacity: 0.9;
    background-color: $gris-light;
    // border-radius: 50px;
    // width: 60px;
    // height: 60px;
    // padding-top: 14px;
    transition: all 100ms ease-in-out;
    &:hover {
      filter: drop-shadow(0px 0px 1px #ffffff);
      opacity: 1;
      transform: translateY(-1px);
    }
  }
  .logoOpenSea {
    border-radius: 50px;
    width: 40px;
    //height: 40px;
    // padding-top: 9px;
    padding: 7px;
    cursor: pointer;
    //opacity: 0.9;
    background-color: $gris-light;
    &:hover {
      filter: drop-shadow(0px 0px 1px #ffffff);
      opacity: 1;
      transform: translateY(-1px);
    }
  }
}

.burger {
  display: none;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-right: -20px;
  .fa {
    font-size: 40px;
    padding: 11px;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
    //opacity: 0.9;
    transition: all 100ms ease-in-out;
    &:hover {
      filter: drop-shadow(0px 0px 1px #ffffff);
      opacity: 1;
      transform: translateY(-1px);
    }
  }
}

.mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  top: 60px;
  left: 0px;
  width: 100%;
}

.mob-nav-item {
  align-items: center;
  white-space: nowrap;
  user-select: none;
  padding: 12px 20px;
  color: $white;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  transition: all 100ms ease-in-out;
  background-color: $gris-dark;
  filter: drop-shadow(0px 0px 1px #ffffff);
  &:hover {
    background-color: $gris-light;
    filter: drop-shadow(0px 0px 1px #ffffff);
    opacity: 1;
  }
  .fab {
    //opacity: 0.9;
    transition: all 100ms ease-in-out;
    &:hover {
      filter: drop-shadow(0px 0px 1px #ffffff);
      opacity: 1;
      transform: translateY(-1px);
    }
  }
  .logoOpenSea {
    border-radius: 50px;
    width: 40px;
    margin-left: 10px;
    //height: 40px;
    // padding-top: 9px;
    cursor: pointer;
    //opacity: 0.9;
    &:hover {
      filter: drop-shadow(0px 0px 1px #ffffff);
      opacity: 1;
      transform: translateY(-1px);
    }
  }
}

.mobLogo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  .fab {
    font-size: 35px;
    cursor: pointer;
    //opacity: 0.9;
    transition: all 100ms ease-in-out;
    margin-right: 10px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 1400px) {
  .logo {
    display: none;
  }
  .logo_small {
    display: block;
  }
  .right {
    // display: none;
    // width: 180px;
    // width: 150px;

    .fab {
      // font-size: 25px;
      // border-radius: 50px;
      // width: 50px;
      // height: 50px;
      // padding-top: 12px;

      font-size: 22px;
      border-radius: 50px;
      width: 40px;
      height: 40px;
      padding-top: 9px;
    }
  }
  .topbar {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    height: 60px;
    padding: 0px 30px;
    width: 100%;
  }
}

@media screen and (max-width: $layout-breakpoint-xlarge) {
  .nav-item {
    font-size: 23px;
  }
  .topbar {
    margin-top: 0px;
  }
}
@media screen and (max-width: $layout-breakpoint-medium) {
  .topbar-container {
    position: fixed;
    border-bottom: 1px solid $gris-light;
  }
  .right {
    display: none;
  }
  .topbar {
    background-color: $gris-dark;
    margin-top: 0px;
  }
  .burger {
    display: flex;
  }
  .logo-png-transparent {
    margin-left: -15px;
    margin-top: -5px;
  }
  .mainNav {
    display: none;
  }
}
</style>
