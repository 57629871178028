<template>
  <div class="page">
    <div class="center">
      <img class="topImg" :src="require('@/assets/Lion_Logo-02 1.png')" />
      <p class="title2 txt1">FOR LLC HOLDERS ONLY</p>
      <p class="title2 txt2">Connect your wallet with a Lucky Lion Club NFT to access the store</p>
      <button class="buttonConnect" @click="connectWallet">{{ accountID === "" ? "Connect wallet" : accountID.substring(1, 9) + "..." + accountID.substring(accountID.length - 6) }}</button>
    </div>
  </div>
</template>

<script>
var Web3 = require("web3");
import abis from "../abis/lucky.json";
export default {
  name: "ConnectWallet",
  data() {
    return {
      address: "",
      accountID: "",
      accountBalance: 0,
      abi: [],
      contract: [],
      wlClaimed: 0,
      // Contract
      isActive: false,
      isPresaleActive: false,
      currentSupply: 0,
      totalTokens: 9777,
      maxSupply: 9777,
      buyLimit: 2,
      nftPrice: 200000000000000000,
      whiteListMaxMint: 2,
      notAllowed: false,
      // Form data
      nftsCountToMint: 2,
      minted: false,
      isAllowed: false,
    };
  },
  async created() {
    await this.loadWeb3();
  },
  methods: {
    async loadWeb3() {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);

        window.ethereum.on("accountsChanged", async (accounts) => {
          await this.setWallet(accounts[0]);
          this.wlClaimed = await this.contract.methods.whiteListClaimed(this.accountID).call();
        });
      } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
      } else {
        window.alert("Non-Ethereum browser detected. You should consider trying MetaMask !");
      }

      await this.loadContractData();
      setInterval(
        function () {
          this.loadContractData();
        }.bind(this),
        1000
      );
    },
    async loadContractData() {
      const web3 = window.web3;
      const networkId = await web3.eth.net.getId();

      if (networkId !== abis.network) {
        window.alert("Please change to ethereum mainnet.");
        return;
      }

      this.abi = abis.abi;
      this.address = abis.address;
      this.contract = new web3.eth.Contract(this.abi, this.address);
      this.nftPrice = await this.contract.methods.NFTPrice().call();
      this.totalTokens = parseInt(await this.contract.methods.MAX_NFT_PUBLIC().call()) + 140;
      this.buyLimit = await this.contract.methods.BUY_LIMIT_PER_TX().call();
      this.isActive = await this.contract.methods.isActive().call();
      this.currentSupply = parseInt(await this.contract.methods.totalSupply().call()) + 140;
      this.isPresaleActive = await this.contract.methods.isPresaleActive().call();
    },
    async setWallet(address) {
      this.accountID = address;
      this.accountBalance = await this.contract.methods.balanceOf(address).call();
      if (this.accountBalance > 0) this.goToExternal("https://merch-luckylionclub.com/");
      //this.accountBalance = await window.web3.eth.getBalance(this.accountID);
    },
    goToExternal(url) {
      window.open(url);
    },
    async connectWallet() {
      console.log("Connect to wallet");
      //const web3js = window.web3;
      if (typeof window.ethereum !== "undefined") {
        this.web3 = new Web3(window.ethereum);
        const accounts = await window.ethereum
          .request({
            method: "eth_requestAccounts",
          })
          .catch((err) => {
            alert(err.message);
          });
        await this.setWallet(accounts[0]);
        console.log("wlClaimed " + this.wlClaimed);
      } else {
        // web3 = new Web3(new Web3.providers.HttpProvider('http://localhost:7545')) GANACHE FALLBACK
        alert("Unable to connect to Metamask");
      }
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.page {
  font-family: MATROSKA;
  text-align: center;
  color: $white;
  margin: auto;
  overflow-x: hidden;
  background-color: $white;
}

.title1,
.title2 {
  font-family: MATROSKA;
  text-transform: uppercase;
  font-size: 40px;
  font-weight: 400;
  margin: auto;
  margin-bottom: 50px;
}

.title2 {
  font-size: 30px;
}

.text {
  text-align: left;
  color: #fff;
  font-family: Barlow;
  font-size: 20px;
  font-weight: 200;
}

button {
  font-family: MATROSKA;
  text-align: center;
  text-transform: uppercase;
  color: $black;
  padding: 20px 50px;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  filter: drop-shadow(0px 0px 1px #ffffff);

  &:hover {
    filter: drop-shadow(0px 0px 2px #ffffff);
    opacity: 1;
    transform: translateY(-1px);
  }
}

@media screen and (max-width: $layout-breakpoint-xlarge) {
  .center {
    width: calc(100% - 50px);
    margin-left: 25px;
    margin-right: 25px;
  }
}

@media screen and (max-width: $layout-breakpoint-large) {
  .title1 {
    font-size: 40px;
    font-weight: 400;
  }
  .title2 {
    font-size: 35px;
  }
  .text {
    font-size: 23px;
  }
}

@media screen and (max-width: $layout-breakpoint-medium) {
  .title1 {
    font-size: 30px;
    margin-bottom: 25px;
  }
  .title2 {
    font-size: 30px;
    margin-bottom: 25px;
    line-height: 40px;
  }
  .text {
    font-size: 20px;
    font-weight: 300;
    line-height: 35px;
  }
  .center {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  button {
    padding: 20px 40px;
    border-radius: 15px;
  }
}

@media screen and (max-width: $layout-breakpoint-small) {
  .title1 {
    font-size: 25px;
  }
  .title2 {
    font-size: 25px;
  }
  .text {
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
  }
  .center {
    padding-top: 50px;
    padding-bottom: 0px;
  }
  button {
    padding: 20px 30px;
    border-radius: 15px;
  }
}

/* Specific styles for this page */

.topImg {
  width: 20%;
  margin: auto;
  min-width: 200px;
  max-width: 400px;
}

.txt1 {
  font-family: "MATROSKA";
  font-style: normal;
  font-weight: 900;
  font-size: 2.5vw;
  color: #1e1e1e;
  margin-top: 50px;
  margin-bottom: 25px;
}

.txt2 {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 300;
  font-size: 2vw;
  text-transform: none;
  text-align: center;
  color: #000000;
  max-width: 900px;
}

.buttonConnect {
  margin: auto;
  background: #383838;
  border: 1px solid #c4c4c4;
  border-radius: 27px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: calc(12px + 1vw);
  line-height: 34px;

  text-align: center;
  color: #ffffff;
}
</style>
